<template>
  <div class="lds-wrapper">
    <div class="lds-fanbase">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style>
.lds-text {
  margin-top: 8px;
}
.lds-wrapper {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.6);
  transition: all .3s ease
}
.lds-fanbase {
  transform: scale(.5) translateY(60px) translateX(0px);
  display: inline-block;
  position: relative;
  width: 96px;
  height: 96px;
  background: #000;
  border-radius: 0.250rem;
}
.lds-fanbase div {
  display: block;
  position: relative;
  left: 16px;
  width: 16px;
  background: #fff;
  height: 4px;
  animation: lds-fanbase 1.3s cubic-bezier(0.2, 0.2, 0.2, 0.5) infinite;
}
.lds-fanbase div:nth-child(1) {
  top: 16px;
  animation-delay: -0.24s;
  width: 56px;
}
.lds-fanbase div:nth-child(2) {
  top: 24px;
  animation-delay: -0.24s;
  width: 56px;
}
.lds-fanbase div:nth-child(3) {
  top: 32px;
  animation-delay: -0.12s;
  width: 32px;
}
.lds-fanbase div:nth-child(4) {
  top: 40px;
  animation-delay: -0.12s;
  width: 32px;
}
.lds-fanbase div:nth-child(5) {
  top: 48px;
  animation-delay: 0s;
  width: 16px;
}
.lds-fanbase div:nth-child(6) {
  top: 56px;
  animation-delay: 0s;
  width: 16px;
}
@keyframes lds-fanbase {
  0% {
    left: 16px;
    width: 8px;
  }
  25%, 100% {
    left: 16px;
  }
}
</style>
