import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class ImportService {
  getImportListByOwner(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/files/import/${ownerId}`;
      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
