import axios from 'axios';

const baseEnv = process.env.VUE_APP_BASEENV;

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class System {
  /* Account */
  getAccounts(ownerId, systemId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/account/${ownerId}/${systemId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(
          url,
          headers,
        )
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createAccount(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/account/CreateAccount`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(
          url,
          payload,
          headers,
        )
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editAccount(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/account/UpdateAccessValues`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .put(
          url,
          payload,
          headers,
        )
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  revokeAccess(ownerId, systemId, userLogin, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/account/${ownerId}/${systemId}/${userLogin}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(
          url,
          headers,
        )
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Config */
  getConfigFile(ownerId, sysId, fileName) {
    return new Promise((resolve, reject) => {
      const url = `${configDomain}/sys/${sysId}/${ownerId}/${fileName}.json`;

      axios
        .get(url)
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getConfigStandard(url) {
    return new Promise((resolve, reject) => {

      axios
        .get(url)
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getConfigStyle(path) {
    return new Promise((resolve, reject) => {
      const url = path;

      axios
        .get(url)
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getDashOverview(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/dashoverview/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOverviewChart(payload) {
    return new Promise((resolve, reject) => {
      const { ownerId, options } = payload;

      let url = `${baseUrl}/dashoverview/refreshgraph/${ownerId}?zoom=y`;

      if (options) {
        if (options.zoom) {
          url = `${baseUrl}/dashoverview/refreshgraph/${ownerId}?zoom=${options.zoom}`
        }
      }

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFanboxMainData(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanboxMain/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Parameters */
  getSystemOwnerCfg(ownerId, param) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/SysOwnerConfig/101/${ownerId}/${param}`;

      axios
        .get(url)
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Images */
  async getImages() {
    const foldersUrl = `${configDomain}/images/api/index.json`;

    try {
      const resp = await axios.get(foldersUrl);

      return resp.data.folders;

    } catch (err) {
      return err;
    }
  }

  getImagePath(directory, fileName, indicator) {
    // Normalize file name
    const filename = fileName.trim()
      .normalize('NFD')
      .replace(/\//g, '')
      .replace(/\s+/g, '-')
      .replace(/\+/g, '')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const fallbackUrl = `${configDomain}/images/api/outro.png`;

    const targetFolder = directory
      .find((folder) => Number(folder.code) === Number(indicator));

    if (targetFolder) {
      return `${configDomain}/images/api/${targetFolder.name}/${filename}.png`;
    } else {
      return fallbackUrl;
    }
  }

  /* Owners */
  getOwners() {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/owners`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Projects */
  getProjectsByOwner(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/projects/${ownerId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Systems */
  getSystemsByOwner(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/systems/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getMembershipPlansByOwner(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/membership/MembershipOwnerList/${ownerId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
