import axios from 'axios';

const connectDomain = process.env.VUE_APP_CONNECTDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;
const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseUrl = `${baseDomain}/${baseEnv}`;

const connectUrl = `${connectDomain}/${baseEnv}`;

export default class User {
  validateToken(token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/ValidateToken`;
      const header = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, header)
        .then(
          (resp) => {
            resolve(resp.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  logout(token) {
    return new Promise((resolve, reject) => {
      const url = `${connectUrl}/logout`;
      const header = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, null, header)
        .then(
          () => {
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
