import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en: {
      Filters: 'Filtros',
      Campanhas: 'Filtros',
      Registros: 'Registros',
      Starterkit: 'Starterkit',
      Dashboards: 'Dashboards',
      Classic: 'Classic ',
      Analytical: 'Analytical ',
      Ecommerce: 'Ecommerce ',
      General: 'General ',
      Apps: 'Apps',
      Chats: 'Chats',
      Calendar: 'Calendar',
      ContactList: 'Contact List',
      ContactGrid: 'Contact Grid',
      Email: 'Email',
      Todo: 'Todo',
      UiElements: 'Ui Elements',
      VuesaxComponents: 'Vuesax Components',
      Alert: 'Alert',
      Avatar: 'Avatar',
      Breadcrumb: 'Breadcrumb',
      Buttons: 'Buttons',
      Cards: 'Cards',
      Chips: 'Chips',
      Collapse: 'Collapse',
      Dialog: 'Dialog',
      Divider: 'Divider',
      Dropdown: 'Dropdown',
      List: 'List',
      Loading: 'Loading',
      Navbar: 'Navbar',
      Notification: 'Notification',
      Numberinput: 'Number input',
      Pagination: 'Pagination',
      Popup: 'Popup',
      Progress: 'Progress',
      Sidebar: 'Sidebar',
      Slider: 'Slider',
      Tabs: 'Tabs',
      Tooltip: 'Tooltip',
      Forms: 'Forms',
      FormElements: 'Form Elements',
      Checkboxes: 'Checkboxes',
      Datepicker: 'Datepicker',
      Input: 'Input',
      Radio: 'Radio',
      Select: 'Select',
      Switch: 'Switch',
      Textarea: 'Textarea',
      Upload: 'Upload',
      FormLayouts: 'Form Layouts',
      FormAction: 'Form Action',
      FormBasic: 'Form Basic',
      FormHorizontal: 'Form Horizontal',
      FormWizard: 'Form Wizard',
      DragnDrop: 'Drag n Drop',
      TableExamples: 'Table Examples',
      Basictable: 'Basic table',
      Editabletable: 'Editable table',
      ExpandDatatable: 'Expand Datatable',
      Filtertable: 'Filter table',
      Miscellaneoustable: 'Miscellaneous table',
      Paginationtable: 'Pagination table',
      Selectedtable: 'Selected table',
      SSTtable: 'SST table',
      Charts: 'Charts',
      ApexCharts: 'ApexCharts',
      ChartJs: 'ChartJs',
      Icons: 'Icons',
      MaterialIcons: 'Material Icons',
      ThemifyIcons: 'Themify Icons',
      ExtraComponents: 'Extra Components',
      Carousel: 'Carousel',
      Widgets: 'Widgets',
      AppWidgets: 'App Widgets',
      DataWidgets: 'Data Widgets'
    },
    fr: {
      Starterkit: 'Kit de démarrage',
      Dashboards: 'Tableaux de bord',
      Classic: 'Tableau de bord classique',
      Analytical: 'Tableau de bord analytique',
      Ecommerce: 'Commerce électronique',
      General: 'Tableau de bord général',
      Apps: 'applications',
      Chats: 'Chats',
      Calendar: 'Calendrier',
      ContactList: 'Liste de contacts',
      ContactGrid: 'ContactGrid',
      Email: 'Email',
      Todo: 'Faire',
      UiElements: 'UiElements',
      VuesaxComponents: 'VuesaxComponents',
      Alert: 'Alerte',
      Avatar: 'Avatar',
      Breadcrumb: 'Miette de pain',
      Buttons: 'Boutons',
      Cards: 'Cartes',
      Chips: 'chips',
      Collapse: 'Effondrer',
      Dialog: 'Dialogue',
      Divider: 'Diviseur',
      Dropdown: 'Menu déroulant',
      List: 'liste',
      Loading: 'Chargement',
      Navbar: 'Barre de navigation',
      Notification: 'Notification',
      Numberinput: 'Nombre dentrée',
      Pagination: 'Pagination',
      Popup: 'Apparaitre',
      Progress: 'Le progrès',
      Sidebar: 'Barre latérale',
      Slider: 'Glissière',
      Tabs: 'Onglets',
      Tooltip: 'Info-bulle',
      Forms: 'Formes',
      FormElements: 'FormElements',
      Checkboxes: 'Cases à cocher',
      Datepicker: 'Sélecteur de date',
      Input: 'Contribution',
      Radio: 'Radio',
      Select: 'Sélectionner',
      Switch: 'Commutateur',
      Textarea: 'Textarea',
      Upload: 'Télécharger',
      FormLayouts: 'Form Layouts',
      FormAction: 'Form Action',
      FormBasic: 'Form Basic',
      FormHorizontal: 'Form Horizontal',
      FormWizard: 'Form Wizard',
      DragnDrop: 'Drag n Drop',
      TableExamples: 'Table Exemples',
      Basictable: 'Basic table',
      Editabletable: 'Editable table',
      ExpandDatatable: 'Développer Datatable',
      Filtertable: 'Table de filtration',
      Miscellaneoustable: 'Miscellaneous table',
      Paginationtable: 'Table de pagination',
      Selectedtable: 'Table sélectionnée',
      SSTtable: 'SSTtable',
      Charts: 'Graphiques',
      ApexCharts: 'ApexCharts',
      ChartJs: 'ChartJs',
      Icons: 'Icônes',
      MaterialIcons: 'Icônes Matérielles',
      ThemifyIcons: 'ThemifyIcons',
      ExtraComponents: 'Extra Components',
      Carousel: 'Carrousel',
      Widgets: 'Widgets',
      AppWidgets: 'App Widgets',
      DataWidgets: 'DataWidgets'
    },
    ch: {
      Starterkit: '入门套件',
      Dashboards: '儀表板',
      Classic: '經典',
      Analytical: '分析型',
      Ecommerce: '電子商務',
      General: '一般',
      Apps: '應用',
      Chats: '聊天室',
      Calendar: '日曆',
      ContactList: '聯繫人列表',
      ContactGrid: '接觸網格',
      Email: '電子郵件',
      Todo: '去做',
      UiElements: 'Ui元素',
      VuesaxComponents: 'Vuesax組件',
      Alert: '警報',
      Avatar: '頭像',
      Breadcrumb: '麵包屑',
      Buttons: '鈕扣',
      Cards: '牌',
      Chips: '薯片',
      Collapse: '坍方',
      Dialog: '對話',
      Divider: '分頻器',
      Dropdown: '落下',
      List: '清單',
      Loading: '載入中',
      Navbar: '導航欄',
      Notification: '通知',
      Numberinput: '號碼輸入',
      Pagination: '分頁',
      Popup: '彈出',
      Progress: '進展',
      Sidebar: '側邊欄',
      Slider: '滑桿',
      Tabs: '標籤',
      Tooltip: '工具提示',
      Forms: '形式',
      FormElements: '表單元素',
      Checkboxes: '選框',
      Datepicker: '日期選擇器',
      Input: '輸入項',
      Radio: '無線電',
      Select: '選擇',
      Switch: '開關',
      Textarea: '文字區',
      Upload: '上載',
      FormLayouts: '表單佈局',
      FormAction: '表單動作',
      FormBasic: '表格基本',
      FormHorizontal: '水平形式',
      FormWizard: '表單嚮導',
      DragnDrop: '拖放',
      TableExamples: '表示例',
      Basictable: '基本表',
      Editabletable: '可編輯表',
      ExpandDatatable: '展開數據表',
      Filtertable: '可過濾的',
      Miscellaneoustable: '雜項',
      Paginationtable: '分頁表',
      Selectedtable: '選定表',
      SSTtable: '穩定表',
      Charts: '圖表',
      ApexCharts: '頂點圖',
      ChartJs: '圖表',
      Icons: '圖示',
      MaterialIcons: '材質圖標',
      ThemifyIcons: '主題圖標',
      ExtraComponents: '額外組件',
      Carousel: '輪播',
      Widgets: '小部件',
      AppWidgets: '應用小部件',
      DataWidgets: '數據小部件'
    },
    gr: {
      Starterkit: 'Starter-Kit',
      Dashboards: 'Dashboards',
      Classic: 'Klassisch',
      Analytical: 'Analytische',
      Ecommerce: 'E-Commerce',
      General: 'Allgemeines',
      Apps: 'Apps',
      Chats: 'Chats',
      Calendar: 'Kalender',
      ContactList: 'Kontaktliste',
      ContactGrid: 'Wenden Sie sich an Grid',
      Email: 'Email',
      Todo: 'Machen',
      UiElements: 'Ui-Elemente',
      VuesaxComponents: 'Vuesax-Komponenten',
      Alert: 'Warnen',
      Avatar: 'Benutzerbild',
      Breadcrumb: 'Paniermehl',
      Buttons: 'Tasten',
      Cards: 'Karten',
      Chips: 'Chips',
      Collapse: 'Zusammenbruch',
      Dialog: 'Dialog',
      Divider: 'Teiler',
      Dropdown: 'Dropdown-Liste',
      List: 'Liste',
      Loading: 'Wird geladen',
      Navbar: 'Navbar',
      Notification: 'Benachrichtigung',
      Numberinput: 'Zahleneingabe',
      Pagination: 'Seitennummerierung',
      Popup: 'Pop-up',
      Progress: 'Fortschritt',
      Sidebar: 'Seitenleiste',
      Slider: 'Schieberegler',
      Tabs: 'Tabs',
      Tooltip: 'Tooltip',
      Forms: 'Formen',
      FormElements: 'Formularelemente',
      Checkboxes: 'Kontrollkästchen',
      Datepicker: 'Datumsauswahl',
      Input: 'Eingang',
      Radio: 'Radio',
      Select: 'Wählen',
      Switch: 'Schalter',
      Textarea: 'Textarea',
      Upload: 'Hochladen',
      FormLayouts: 'Formularlayouts',
      FormAction: 'Formularaktion',
      FormBasic: 'Formular Basic',
      FormHorizontal: 'Formular Horizontal',
      FormWizard: 'Formzauberer',
      TableExamples: 'Tabellenbeispiele',
      Basictable: 'Basistabelle',
      Editabletable: 'Bearbeitungstabelle',
      ExpandDatatable: 'Erweitern Sie Datatable',
      Filtertable: 'Filtertisch',
      Miscellaneoustable: 'Verschiedenes',
      Paginationtable: 'Paginierungstabelle',
      Selectedtable: 'AusgewählteTabelle',
      SSTtable: 'SST-Tisch',
      Charts: 'Diagramme',
      ApexCharts: 'Scheitelpunktdiagramme',
      ChartJs: 'DiagrammJs',
      Icons: 'Icons',
      MaterialIcons: 'Materielle Ikonen',
      ThemifyIcons: 'Themify Ikonen',
      ExtraComponents: 'Zusätzliche Komponenten',
      Carousel: 'Karussell',
      Widgets: 'Widgets',
      AppWidgets: 'App-Widgets',
      DataWidgets: 'Daten-Widgets'
    },
  },

})