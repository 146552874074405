import Vue from 'vue';
import Vuesax from 'vuesax';
import App from './App.vue';

// Vuesax styles
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';

// Vuex Store
import store from './store/store';

// Theme Configurations
import '../themeConfig.js';

import Vuebar from 'vuebar';
Vue.use(Vuebar);

import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.use(VueYouTubeEmbed);

// Theme Configurations
import 'prismjs';
import 'prismjs/themes/prism.css';
import VsPrism from './components/prism/VsPrism.vue';

Vue.component(VsPrism.name, VsPrism);

import VsSidebarGroup from './components/vs-sidebar-group/Sidebar-Group.vue';

Vue.component(VsSidebarGroup.name, VsSidebarGroup);

// i18n
import i18n from './i18n/i18n.js';

// Vue Router
import router from './router';
Vue.config.productionTip = false;

Vue.use(Vuesax);

import { Datetime } from 'vue-datetime';

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';

Vue.use(Datetime);
Vue.component('Datetime', Datetime);

// Social Sharing
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

import '@/assets/scss/style.scss';
import './filters';
