/* Composables */
import Vue from 'vue';
import themeConfig from '@/../themeConfig.js'
import mails from '@/views/apps/email/emails'
import ImportService from '../services/import';


/* Helpers */
import moment from 'moment';

/* Plugins */
import Vuex from 'vuex';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoadingActive: false,
    menuSidebar: [],
    menuSelectItem: [],
    menuBreadcrumb: '',
    campInfo: {},
    prizeDrawInfo: {},
    tempStrhtml: '',
    tempStrName: '',
    tempStrId: 0,
    ownerCopyright: '',
    emailTemplateLogo: '',
    sysLogin: '',
    loginData: {},
    logoutUrl: '',
    surveyStats: {},
    negStats: {},
    categoriaFiltro: [],
    selectedContacts: [],
    filterQuestions: [],
    filterAllQuestions: [],
    filterOptions: [],
    filterAnswers: [],
    filterName: '',
    selectedProject: 0,
    selectedSurvey: 0,
    selectedOwner: 0,
    sysID: 0,
    ownerID: 0,
    prj: '',
    tk: '',
    accessToken: null,
    visibilityOptions: {},
    boxstat: {},
    registerUrl: '',
    configBox: {},
    select_categ: 0,
    boxTitle: '',
    surveyStruct: {},
    questionTypes: [],
    surveyGlobalQuestions: [],
    surveyGlobalLists: [],
    surveyAnswerLists: [],
    fromFilter: null,
    filterUid: null,
    events: [],
    inviteList: null,
    selectedEvent: '',
    theme: themeConfig.theme,
    holdList: [], // used to navigate between single fan view list and id's profile
    incrementKey: 0,
    isSidebarActive: false,
    isSidebarReduced: themeConfig.sidebarCollapsed,
    sidebarWidth: 'default',
    themeColor: themeConfig.themeColor,
    // Calendar Events
    calendarEvents: [{
      title: 'test',
      allDay: true,
      start: moment(),
      end: moment().add(1, 'd'),
    }],
    // Email States
    emails: mails,
    filtered_mail: 'Inbox'
  },
  mutations: {
    INCREMENT_key(state) {
      state.incrementKey++;
    },
    SET_isLoadingActive(state, val) {
      state.isLoadingActive = val;
    },
    SET_menuSidebar(state, val) {
      state.menuSidebar = val;
    },
    SET_menuSelectItem(state, val) {
      state.menuSelectItem = val;
    },
    SET_menuBreadcrumb(state, val) {
      state.menuBreadcrumb = val;
    },
    SET_filterUid(state, val) {
      state.filterUid = val;
    },
    SET_tempStrId(state, val) {
      state.tempStrId = val;
    },
    SET_tempStrName(state, val) {
      state.tempStrName = val;
    },
    SET_tempStrhtml(state, val) {
      state.tempStrhtml = val;
    },
    SET_campInfo(state, val) {
      state.campInfo = val;
    },
    SET_prizeDrawInfo(state, val) {
      state.prizeDrawInfo = val;
    },
    SET_ownerCopyright(state, val) {
      state.ownerCopyright = val;
    },
    SET_emailTemplateLogo(state, val) {
      state.emailTemplateLogo = val;
    },
    SET_boxTitle(state, val) {
      state.boxTitle = val;
    },
    SET_CONFIGBOX(state, val) {
      state.configBox = val;
    },
    SET_HOLDLIST(state, val) {
      state.holdList = val;
    },
    SET_categoriaFiltro(state, values) {
      state.categoriaFiltro = values;
    },
    SET_fromFilter(state, val) {
      state.fromFilter = val;
    },
    SET_filterName(state, value) {
      state.filterName = value;
    },
    SET_sysID(state, value) {
      state.sysID = value;
    },
    SET_ownerID(state, value) {
      state.ownerID = parseInt(value);
    },
    SET_sysLogin(state, value) {
      state.sysLogin = value;
    },
    SET_LogoutUrl(state, value) {
      state.logoutUrl = value;
    },
    SET_RegisterUrl(state, value) {
      state.registerUrl = value;
    },
    SET_LoginData(state, value) {
      state.loginData = value;
    },
    SET_filterAnswers(state, value) {
      state.filterAnswers = value;
    },
    SET_filterOptions(state, value) {
      state.filterOptions = value;
    },
    SET_filterQuestions(state, value) {
      state.filterQuestions = value;
    },
    SET_filterAllQuestions(state, value) {
      state.filterAllQuestions = value;
    },
    SET_selectedContacts(state, value) {
      state.selectedContacts = value;
    },
    SET_negStats(state, value) {
      state.negStats = value;
    },
    SET_surveyStats(state, value) {
      state.surveyStats = value;
    },
    SET_prj(state, value) {
      state.prj = value;
    },
    SET_selectedProject(state, value) {
      state.selectedProject = value;
    },
    SET_selectedSurvey(state, value) {
      state.selectedSurvey = value;
    },
    SET_selectedOwner(state, value) {
      state.selectedOwner = value;
    },
    SET_accessToken(state, value) {
      state.accessToken = value;
    },
    SET_visibilityOptions(state, value) {
      state.visibilityOptions = value;
    },
    SET_inviteList(state, value) {
      state.inviteList = value;
    },
    SET_selectedEvent(state, value) {
      state.selectedEvent = value;
    },
    SET_events(state, value) {
      state.events = value;
    },
    // survey mutations
    SET_survey(state, value) {
      state.surveyStruct = value;
    },
    SET_questionTypes(state, value) {
      state.questionTypes = value;
    },
    SET_surveyGlobalQuestions(state, value) {
      state.surveyGlobalQuestions = value;
    },
    SET_surveyGlobalLists(state, value) {
      state.surveyGlobalLists = value;
    },
    SET_surveyAnswerLists(state, value) {
      state.surveyAnswerLists = value;
    },
    SET_chapters(state, value) {
      state.surveyStruct.chapters = value;
    },
    RESET_HOLDLIST(state) {
      state.holdList = [];
    },
    DELETE_chapter(state, index) {
      state.surveyStruct.chapters.splice(index, 1);
    },
    UPDATE_requirement(state, {indexChap, indexRequir, value}) {
      state.surveyStruct.chapters[indexChap].requirements.splice(indexRequir, 1, value);
    },
    ADD_requirement(state, {indexChap, value}) {
      state.surveyStruct.chapters[indexChap].requirements.push(value);
    },
    DELETE_requirement(state, {indexChap, indexRequir}) {
      state.surveyStruct.chapters[indexChap].requirements.splice(indexRequir, 1);

      state.surveyStruct.chapters[indexChap].requirements.forEach((requir, index) => {
        requir.code = (indexChap + 1) + '.' + (index + 1);
        requir.id = ((indexChap + 1) * 100) + parseInt((index + 1));
      });
    },
    DELETE_question(state, {indexChap, indexRequir, indexQuest}) {
      state.surveyStruct.chapters[indexChap].requirements[indexRequir].questions.splice(indexQuest, 1)
    },
    UPDATE_question(state, {indexChap, indexRequir, indexQuest, value}) {
      state.surveyStruct.chapters[indexChap].requirements[indexRequir].questions.splice(indexQuest, 1, value)
    },
    ADD_question(state, {indexChap, indexRequir, value}) {
      state.surveyStruct.chapters[indexChap].requirements[indexRequir].questions.push(value)
    },
    ADD_surveyAnswerLists(state, value) {
      state.surveyAnswerLists.push(value)
    },
    UPDATE_surveyAnswerLists(state, {itemIndex, value}) {
      state.surveyAnswerLists.splice(itemIndex, 1, value)
    },
    UPDATE_surveyGlobalLists(state, {itemIndex, value}) {
      state.surveyGlobalLists.splice(itemIndex, 1, value)
    },
    MOVE_question(state, {
      fromIndexQuestion,
      fromIndexRequir,
      toIndexQuestion,
      toIndexRequir,
      fromIndexChap,
      toIndexChap
    }) {
      const itemToMove = state.surveyStruct.chapters[fromIndexChap].requirements[fromIndexRequir].questions.splice(fromIndexQuestion, 1)[0]
      state.surveyStruct.chapters[toIndexChap].requirements[toIndexRequir].questions.splice(toIndexQuestion, 0, itemToMove)
    },
    MOVE_requirement(state, {fromIndexRequir, toIndexRequir, fromIndexChap, toIndexChap}) {
      const itemToMove = state.surveyStruct.chapters[fromIndexChap].requirements.splice(fromIndexRequir, 1)[0];

      state.surveyStruct.chapters[toIndexChap].requirements.splice(toIndexRequir, 0, itemToMove);

      state.surveyStruct.chapters[fromIndexChap].requirements.forEach((requir, index) => {
        requir.code = (fromIndexChap + 1) + '.' + (index + 1);
        requir.id = ((fromIndexChap + 1) * 100) + parseInt((index + 1));
      });

      state.surveyStruct.chapters[toIndexChap].requirements.forEach((requir, index) => {
        requir.code = (toIndexChap + 1) + '.' + (index + 1);
        requir.id = ((toIndexChap + 1) * 100) + parseInt((index + 1));
      });
    },
    MOVE_chapter(state, {fromIndexChapter, toIndexChapter}) {
      const itemToMove = state.surveyStruct.chapters.splice(fromIndexChapter, 1)[0];

      state.surveyStruct.chapters.splice(toIndexChapter, 0, itemToMove);

      state.surveyStruct.chapters.forEach((c, index) => {
        c.id = index + 1;
        c.code = c.id.toString();
      });

      state.surveyStruct.chapters[fromIndexChapter].requirements.forEach((requir, index) => {
        requir.code = (fromIndexChapter + 1) + '.' + (index + 1);
        requir.id = ((fromIndexChapter + 1) * 100) + parseInt((index + 1));
      });

      state.surveyStruct.chapters[toIndexChapter].requirements.forEach((requir, index) => {
        requir.code = (toIndexChapter + 1) + '.' + (index + 1);
        requir.id = ((toIndexChapter + 1) * 100) + parseInt((index + 1));
      });
    },
    IS_SIDEBAR_ACTIVE(state, value) {
      state.isSidebarActive = value;
    },
    TOGGLE_REDUCE_SIDEBAR(state, val) {
      state.isSidebarReduced = val;
    },
    SET_THEME(state, val) {
      state.theme = val;
    },
    SET_THEME_COLOR(state, val) {
      state.themeColor = val;
    },
    SET_SIDEBAR_WIDTH(state, width) {
      state.sidebarWidth = width;
    },
    // Add Calendar event
    ADD_CALENDAR_EVENT(state, event) {
      const newEvent = Object.assign({}, event);
      state.calendarEvents.push(newEvent);
    },
    // Filter emails
    FILTERED_MAIL(state, filterCategory) {
      state.filtered_mail = filterCategory
    },
    SET_SELECT_CATEG(state, idCateg) {
      state.select_categ = idCateg;
    },
    SET_BOXSTAT(state, boxStat) {
      state.boxstat = boxStat;
    },
  },
  actions: {
    set_isLoadingActive({commit}, val) {
      commit('SET_isLoadingActive', val);
    },
    set_menuSidebar({commit}, val) {
      commit('SET_menuSidebar', val);
    },
    push_menuSidebar({state, commit}, val) {
      let array = [...state.menuSidebar];
      array.push(val);
      commit('SET_menuSidebar', array);
    },
    splice_menuSidebar({state, commit}, start) {
      let array = [...state.menuSidebar];
      array.splice(start);
      commit('SET_menuSidebar', array);
    },
    pop_menuSidebar({state, commit}) {
      let array = [...state.menuSidebar];

      if (array.length > 1) array.pop();

      commit('SET_menuSidebar', array);
    },
    set_menuSelectItem({commit}, val) {
      commit('SET_menuSelectItem', val);
    },
    push_menuSelectItem({state, commit}, val) {
      let array = [...state.menuSelectItem];
      array.push(val);
      commit('SET_menuSelectItem', array);
    },
    splice_menuSelectItem({ state, commit }, payload) {
      let array = [...state.menuSelectItem];

      if (typeof payload === 'number') {
        array.splice(payload);
      } else {
        array.splice(array.length - 1, 1, payload);
      }

      commit('SET_menuSelectItem', array);
    },
    pop_menuSelectItem({ state, commit }) {
      let array = [...state.menuSelectItem];
      array.pop();
      commit('SET_menuSelectItem', array);
    },
    set_menuBreadcrumb({ commit }, val) {
      commit('SET_menuBreadcrumb', val);
    },
    updateSidebarWidth({ commit }, width) {
      commit('SET_SIDEBAR_WIDTH', width);
    },
    // Calendar Actions
    addCalendarEvent({ commit }, event) {
      commit('ADD_CALENDAR_EVENT', event);
    },
    // Email Actions
    filteredMail({ commit }, filterCategory) {
      commit('FILTERED_MAIL', filterCategory);
    },
    // Survey Actions
    getSurvey({ commit }, surveyID) {
      return surveyService.getSurveyStruct(surveyID, localStorage.getItem('accessToken'))
        .then((resp) => commit('SET_survey', resp))
        .catch((err) => alert(err));
    },
    cleanSurveyStruct({ commit }) {
      commit('SET_survey', {});
    },
    getQuestionTypes({ commit }) {
      return surveyService.getSurveyQuestTypes(localStorage.getItem('accessToken'))
        .then((resp) => commit('SET_questionTypes', resp))
        .catch((err) => alert(err));
    },
    getSurveyGlobalQuestions({ commit }) {
      return surveyService.getQuestions(localStorage.getItem('accessToken'))
        .then((resp) => commit('SET_surveyGlobalQuestions', resp))
        .catch((err) => alert(err));
    },
    getSurveyAnswerLists({ commit }, prj) {
      return surveyService.getSurveyAnswerLists(prj, localStorage.getItem('accessToken'))
        .then((resp) => commit('SET_surveyAnswerLists', resp))
        .catch((err) => alert(err));
    },
    postSurveyGlobalAndAnswerLists({ dispatch }, { listName, listArray, origin, action }) {
      return surveyService
        .postSurveyAnswerList(listName, listArray, origin, localStorage.getItem('accessToken'))
        .then((resp) => {
          if (origin !== 'g' && action === 'add') {
            return dispatch('getSurveyAnswerLists', origin);
          } else if (origin === 'g' && action === 'add') {
            return dispatch('getSurveyGlobalLists');
          } else {
            return resp;
          }
        })
        .catch((err) => alert(err));
    },
    getSurveyGlobalLists({ commit }) {
      return surveyService.getSurveyGlobalLists(localStorage.getItem('accessToken'))
        .then((resp) => commit('SET_surveyGlobalLists', resp))
        .catch((err) => alert(err));
    },
    updateChapters({ commit }, chapters) {
      // add API pont and catch error
      return commit('SET_chapters', chapters);
    },
    deleteChapter({ commit }, index) {
      // add API pont and catch error
      return commit('DELETE_chapter', index);
    },
    updateRequirement({ commit }, { indexChap, indexRequir, value }) {
      // add API pont and catch error
      return commit('UPDATE_requirement', { indexChap, indexRequir, value });
    },
    addRequirement({ commit }, { indexChap, value }) {
      // add API pont and catch error
      return commit('ADD_requirement', {indexChap, value});
    },
    deleteRequirement({ commit }, { indexChap, indexRequir }) {
      // add API pont and catch error
      return commit('DELETE_requirement', { indexChap, indexRequir });
    },
    deleteQuestion({ commit }, {indexChap, indexRequir, indexQuest}) {
      // add API pont and catch error
      return commit('DELETE_question', {indexChap, indexRequir, indexQuest});
    },
    updateQuestion({ commit }, { indexChap, indexRequir, indexQuest, value }) {
      return commit('UPDATE_question', {indexChap, indexRequir, indexQuest, value});
    },
    addQuestion({ commit }, { indexChap, indexRequir, value }) {
      return commit('ADD_question', { indexChap, indexRequir, value });
    },
    moveQuestion({ commit }, {
      fromIndexQuestion,
      fromIndexRequir,
      toIndexQuestion,
      toIndexRequir,
      fromIndexChap,
      toIndexChap,
    }) {
      return commit('MOVE_question', {
        fromIndexQuestion,
        fromIndexRequir,
        toIndexQuestion,
        toIndexRequir,
        fromIndexChap,
        toIndexChap,
      });
    },
    moveRequirement({ commit }, { fromIndexRequir, toIndexRequir, fromIndexChap, toIndexChap }) {
      return commit('MOVE_requirement', {fromIndexRequir, toIndexRequir, fromIndexChap, toIndexChap});
    },
    moveChapter({ commit }, {fromIndexChapter, toIndexChapter}) {
      return commit('MOVE_chapter', {fromIndexChapter, toIndexChapter});
    },
    saveSurveyStruct({ state }) {
      return surveyService.postSurveyStruct(state.surveyStruct, state.accessToken);
    },
    set_accessToken({ commit }, value) {
      localStorage.setItem('accessToken', value);
      commit('SET_accessToken', value);
    },
    remove_accessToken({ commit }) {
      localStorage.removeItem('accessToken');
      commit('SET_accessToken', null);
    },
    setFromFilter({ commit }, fromFilter) {
      commit('SET_fromFilter', fromFilter);
    },
    setFilterUid({ commit }, filterUid) {
      commit('SET_filterUid', filterUid);
    },
    set_inviteList({ commit }, value) {
      commit('SET_inviteList', value);
    },
    set_selectedEvent({ commit }, value) {
      commit('SET_selectedEvent', value);
    },
    set_events({ commit }, value) {
      commit('SET_events', value);
    },
    async fetchImportList({ commit }, ownerId) {
      try {
        const importService = new ImportService();
        const importList = await importService.getImportListByOwner(ownerId);
        commit('setImportList', importList);
      } catch (error) {
        console.error('Erro ao buscar a lista de importações:', error.message);
      }
    },
  },
  getters: {
    // Get Filtered Email
    getFilteredMails: state => state.emails.filter((email) => {
      if (state.filtered_mail == 'Inbox') return email.mail_type == 'inbox';
      if (state.filtered_mail == 'Spam') return email.mail_type == 'spam';
      if (state.filtered_mail == 'Draft') return email.mail_type == 'draft';
      if (state.filtered_mail == 'Sent') return email.mail_type == 'sent';
    }),
    getCurrentMail: state => (emailId) => state.emails.filter((email) => email.id == emailId)[0],
    getInboxCount: state => state.emails.filter((email) => email.mail_type == 'inbox').length,
  }
});
