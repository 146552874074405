import Vue from 'vue';
const numeral = require('numeral');

Vue.filter('formatNumber', function (value) {
  if (!value) return '0';
  return numeral(value).format('0.0');
});

Vue.filter('formatLocale', function (value) {
  if (!value || isNaN(value)) return '0';
  return Number(value).toLocaleString();
});

Vue.filter('formatLocaleFixed2', function (value) {
  if (!value || isNaN(value)) return '0';
  return Number(value).toFixed(2).toLocaleString();
});

Vue.filter('formatLocaleFixed0', function (value) {
  if (!value || isNaN(value)) return '0';
  return Number(value).toFixed(0).toLocaleString();
});

Vue.filter('toMoney', function (value) {
  if (!value) return 'R$ 00,00';
  return (
    'R$ ' +
    Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
});

Vue.filter('toReduced', function (value) {
  if (!value) return '';
  if (value.length < 15) return value;

  let reducedString = value.substring(0, 15);
  return `${reducedString}...`;
});
