import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class Survey {
  getSurveys(type, prj, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/survey/${type}/${prj}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveysOwner(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/SurveysOwner/${ownerId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        )
    });
  }

  getSurveyOwnerById(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/SurveysOwner/BySurvey/${surveyId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        )
    });
  }

  getSurveyTypes(token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveytypes/0`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Export */
  exportQuestion(surveyId, questionId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyresult/exportQuestion/${surveyId}/${questionId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, headers)
        .then(
          (resp) => resolve(resp.data),
          (err) => reject(err),
        );
    });
  }

  exportIndicatorValue(indId, projectId, title, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/indicators/exportIndicatorValue/${indId}/${projectId}/${title}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, headers)
        .then(
          (resp) => resolve(resp.data),
          (err) => reject(err),
        );
    });
  }

  getSurveyStatsExportUrl(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveystats/csv/${surveyId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, headers)
        .then(
          (resp) => resolve(resp),
          (err) => reject(err),
        );
    });
  }

  getSurveyExportCSV(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyresult/export/${surveyId}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(url, headers)
        .then(
          (resp) => resolve(resp),
          (err) => reject(err),
        );
    });
  }

  getSurveyResult(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyresult/${surveyId}/short`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveyResultFull(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyresult/${surveyId}/full`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Structure */
  getSurveyStruct(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveystruct/${surveyId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  postSurveyStruct(surveyStruct, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/SurveyStruct`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .post(url, surveyStruct, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Parameters */
  getSurveyParams(token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyParams`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveyParamsById(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyParams/${surveyId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setSurveyParams(surveyId, param, value, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyParams`;

      const data = { surveyID: surveyId, param, value };

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, data,headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* URL */
  updateLink(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyParams/updateLink`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .put(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Stats */
  getBox(boxName, owner, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/BoxStats/${boxName}/${owner}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getNegChart(
    owner,
    businessType,
    options,
    token
  ) {
    return new Promise((resolve, reject) => {
      let url = `${baseUrl}/NegStats/refreshGraph/${owner}/${businessType}?zoom=y`;

      if (options && options.zoom) {
        url = `${baseUrl}/NegStats/refreshGraph/${owner}/${businessType}?zoom=${options.zoom}`
      }
      const headers = { headers: { Authorization: `Bearer ${token}` } };


      axios.get(url, headers)
        .then((resp) => resolve(resp.data.data))
        .catch((err) => reject(err));
    });
  }

  getNegStats(owner, boxName, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/NegStats/${owner}/${boxName}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios.get(url, headers)
        .then((resp) => resolve(resp.data.data))
        .catch((err) => reject(err));
    });
  }

  /* Questions - Indicators */
  getSurveyList(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/QuestOptionsList/${payload}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveyGlobalLists(token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/QuestOptionsList/g`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveyQuestTypes(token) {
    return new Promise((resolve, reject) => {
      console.log(token)
      const url = `${baseUrl}/Surv_question_types`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getBoxesAsFilters(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/questcateg/boxesAsFilters/${ownerId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getIndicators(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/indicators/${ownerId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getNewQuestionId(ownerId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/questions/OwnerQuestionId/${ownerId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        )
    })
  }

  getQuestions(token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/questions`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Answers - Options */
  getAnswer(ownerId, questionId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/answer/${ownerId}/${questionId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSurveyAnswerLists(surveyId, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/QuestOptionsList/${surveyId}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  postSurveyAnswerList(listName, list, origin, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/QuestOptionsList/${origin}/${listName}`;
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      axios
        .post(url, list, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
