<template>
  <div id="app">
    <Loading v-if="$store.state.isLoadingActive" />

    <router-view />
  </div>
</template>

<script>
/* Components */
import Loading from '@/components/Loading';

/* Config */
import themeConfig from '@/../themeConfig.js';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;
import System from './services/system';
import User from './services/user';

const systemService = new System();
const userService = new User();

export default {
  name: 'App',
  components: { Loading },
  watch: {
    '$store.state.theme'(val) {
      this.toggleBodyClass(val);
    },
  },
  mounted() {
    this.getParams()
      .then(this.toggleBodyClass(themeConfig.theme))
      .catch((err) => (window.location.href = err));
  },
  methods: {
    /* API */
    fetchToken() {
      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get('tk');

      if (!token) {
        // get token from localstorage
        return localStorage.getItem('accessToken');
      } else {
        // get token from url params
        return token;
      }
    },
    getParams() {
      return new Promise((resolve, reject) => {
        const token = this.fetchToken();

        if (!token) return reject('http://fanbase.com.br');

        // set token in store
        this.$store.dispatch('set_accessToken', token);

        // clear params from url
        this.$router.replace({ path: '/', params: {}, hash: '' });

        // redirect from fanbox v2
        const hash = window.location.hash;

        if (hash.length > 0) {
          const path = hash.substring(hash.indexOf('/'));
          this.$router.push({ path });
        }

        // validate token
        this.$store.dispatch('set_isLoadingActive', true);

        userService.validateToken(token)
          .then((resp) => {
            if (resp.header.codigo == 1) {
              const { ownerId } = resp.data;

              this.$store.commit('SET_LoginData', resp.data);
              this.$store.commit('SET_LogoutUrl', resp.data.loginUrl);
              this.$store.commit('SET_RegisterUrl', resp.data.registerUrl);
              this.$store.commit('SET_sysLogin', resp.data.subdomainLogin);
              this.$store.commit('SET_ownerID', ownerId);
              this.$store.commit('SET_selectedProject', resp.data.prjId);
              this.$store.commit('SET_sysID', resp.data.sysId);

              systemService
                .getConfigFile(
                  ownerId,
                  resp.data.sysId,
                  'configbox3',
                )
                .then((data) => {
                  // Set options
                  if (data.visibilityOptions) {
                    this.$store.commit('SET_visibilityOptions', data.visibilityOptions);
                  }

                  // Get owner configs
                  systemService.getSystemOwnerCfg(ownerId, 'owner-copyright')
                    .then((resp) => {
                      if (resp.data && resp.data.length > 0) {
                        this.$store.commit('SET_ownerCopyright', resp.data);
                      } else {
                        this.$store.commit(
                          'SET_ownerCopyright',
                          'Fanbase Network Tecnologia e Serviços Ltda',
                        );
                      }
                    });

                  systemService.getSystemOwnerCfg(ownerId, 'email-template-logo')
                    .then((resp) => {
                      if (resp.data && resp.data.length > 0) {
                        this.$store.commit('SET_emailTemplateLogo', resp.data);
                      } else {
                        const fallbackUrl = `${configDomain}/sys/101/${ownerId}/logo.png`;
                        this.$store.commit('SET_emailTemplateLogo', fallbackUrl);
                      }
                    });

                  // Get menu structure
                  if (data.configBoxPath !== '') {
                    systemService.getConfigStandard(data.configBoxPath)
                      .then((config) => {
                        this.$store.dispatch('push_menuSidebar', config.categorias);
                      });
                  } else if (data.configBoxCustom !== '' && !data.configBoxPath){
                    this.$store.dispatch('push_menuSidebar', data.configBoxCustom);
                  } else {
                    this.$store.dispatch('push_menuSidebar', data.categorias);
                  }

                  // Get style template
                  systemService.getConfigStyle(data.templateStylesPath)
                    .then(
                      (configStyle) => {
                        data.cor1 = configStyle.default.cor1;
                        data.cor2 = configStyle.default.cor2;
                        data.cor2TextColor = configStyle.default.cor2TextColor;
                        data.fontFamilyWelcomeMsg = configStyle.default.fontFamily;
                        data.bgCardColors = configStyle.fanbox.menuBgCard;

                        this.$store.commit('SET_CONFIGBOX', data);

                        document.documentElement.style.setProperty(
                          '--bg-color',
                          this.$store.state.configBox.cor1
                        );

                        this.$vs.theme({
                          primary: configStyle.fanbox.menuBgCard,
                          secondary: this.$store.state.configBox.cor2,
                        });
                      },
                    );

                  this.$store.dispatch('set_isLoadingActive', false);

                  // Get dashboard data
                  systemService.getFanboxMainData(ownerId)
                    .then(
                      (ret) => {
                        this.$store.commit('SET_BOXSTAT', ret);
                        resolve('Ok');
                      },
                      (err) => {
                        alert(err);
                        resolve('Err');
                      },
                    );
                });
            } else {
              this.$store.dispatch('remove_accessToken');
              reject('http://fanbase.com.br');
            }
          })
          .catch(() => {
            this.$store.dispatch('remove_accessToken');
            reject('http://fanbase.com.br');
          })
          .finally(() => this.$store.dispatch('set_isLoadingActive', false));
      });
    },
    /* Helpers */
    toggleBodyClass(className) {
      if (className === 'dark') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    },
  },
}
</script>

<style>
/* Scrollbar */
::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  border-radius: 4px;
  padding-left: 6px;
}

::-webkit-scrollbar {
  width: 16px;
  border-radius: 4px;
  background: #F4F4F4;
  cursor: grab !important;
}

::-webkit-scrollbar-thumb {
  background: #5C5C5C;
  border-radius: 4px;
  cursor: grab !important;
}
</style>
