import Vue from 'vue';
import Vuesax from 'vuesax';

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#000000', // #2962FF
      success: '#36bea6',
      danger: '#f62d51',
      warning: 'rgb(255, 130, 0)',
      dark: '#615d5d'
    }
  }
});

// CONFIGS
const themeConfig = {
  theme: 'light',			// options: dark and light theme
  logotitle: 'Fanbase Network',
  sidebarCollapsed: false,			// options: true (mini-sidebar), false(default)
  topbarColor: '',			// options: anycolor you can use
  themeColor: 'black'			// options: anycolor you can use
}

export default themeConfig;
